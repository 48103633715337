<template>
  <b-form-group
    label="İl"
    label-for="city"
  >
    <validation-provider
      #default="{ errors }"
      name="İl"
      rules="required"
    >
      <v-select
        id="city"
        v-model="customer.id_com_city"
        label="title"
        :reduce="city => city.id"
        :options="cities"
        placeholder="İl"
        @input="getDistricts"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>
<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'

export default {
  name: 'City',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      locale: 'tr',
    }
  },
  computed: {
    customer() {
      return this.$store.getters['customers/getCustomer']
    },
    cities() {
      return this.$store.getters['cities/getCities']
    },
  },
  created() {
    localize(this.locale)
    this.getCities()
  },
  methods: {
    getCities() {
      this.$store.dispatch('cities/citiesList', {
        select: ['com_city.id AS id', 'com_city.title AS title'],
      })
    },
    getDistricts() {
      if (this.customer.id_com_city) {
        this.customer.id_com_district = null
        this.$store.dispatch('districts/districtsList', {
          select: ['com_district.id AS id', 'com_district.title AS title'],
          where: {
            id_com_city: this.customer.id_com_city,
          },
        })
      }
    },
  },
}
</script>

<style scoped>

</style>
