<template>
  <div>
    <b-row>
      <b-col>
        <b-card-title>Satış Bilgileri</b-card-title>
      </b-col>
    </b-row>
    <b-row v-if="stockData.id">
      <b-col>
        <b-list-group>
          <b-list-group-item class="d-flex justify-content-between align-items-center">
            <span class="font-weight-bolder">Marka - Model</span>
            <span>{{ stockData.brand }} {{ stockData.model }}</span>
          </b-list-group-item>
          <b-list-group-item class="d-flex justify-content-between align-items-center">
            <span class="font-weight-bolder">Donanım Paketi</span>
            <span>{{ stockData.hardware }}</span>
          </b-list-group-item>
          <b-list-group-item class="d-flex justify-content-between align-items-center">
            <span class="font-weight-bolder">Model Yılı</span>
            <span>{{ stockData.myear }}</span>
          </b-list-group-item>
          <b-list-group-item class="d-flex justify-content-between align-items-center">
            <span class="font-weight-bolder">Silindir Hacmi</span>
            <span>{{ stockData.cc }}</span>
          </b-list-group-item>
          <b-list-group-item class="d-flex justify-content-between align-items-center">
            <span class="font-weight-bolder">Yakıt - Vites</span>
            <span>{{ stockData.fuel }} - {{ stockData.gear }}</span>
          </b-list-group-item>
          <b-list-group-item class="d-flex justify-content-between align-items-center">
            <span class="font-weight-bolder">Renk</span>
            <span>{{ stockData.color }}</span>
          </b-list-group-item>
          <b-list-group-item class="d-flex justify-content-between align-items-center">
            <span class="font-weight-bolder">Şase - Motor No</span>
            <span>{{ stockData.chasis }} - {{ stockData.engine }}</span>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col cols="12">
        <sale-price />
      </b-col>
      <b-col md="6">
        <otv-rate />
      </b-col>
      <b-col md="6">
        <kdv-rate />
      </b-col>
      <b-col md="6">
        <banderole-price />
      </b-col>
      <b-col md="6">
        <plate-price />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BCardTitle, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import { required } from '@validations'
import SalePrice from '@/views/Sales/Sale_orderforms/forms/elements/SalePrice.vue'
import OtvRate from '@/views/Sales/Sale_orderforms/forms/elements/OtvRate.vue'
import KdvRate from '@/views/Sales/Sale_orderforms/forms/elements/KdvRate.vue'
import BanderolePrice from '@/views/Sales/Sale_orderforms/forms/elements/BanderolePrice.vue'
import PlatePrice from '@/views/Sales/Sale_orderforms/forms/elements/PlatePrice.vue'

export default {
  name: 'SaleInfo',
  components: {
    BRow,
    BCol,
    BCardTitle,
    BListGroup,
    BListGroupItem,
    SalePrice,
    OtvRate,
    KdvRate,
    BanderolePrice,
    PlatePrice,
  },
  data() {
    return {
      required,
      locale: 'tr',
    }
  },
  computed: {
    stockData() {
      return this.$store.getters['stock/getStock']
    },
    saleOrderForm() {
      return this.$store.getters['saleOrderforms/getSaleOrderForm']
    },
  },
  created() {
    this.getOtvRates()
    this.getKdvRates()
  },
  methods: {
    getOtvRates() {
      this.$store.dispatch('otvRates/otvRatesList')
    },
    getKdvRates() {
      this.$store.dispatch('kdvRates/kdvRatesList')
    },
  },
}
</script>
