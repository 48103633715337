<template>
  <div>
    <b-row class="mb-2">
      <b-col class="d-flex justify-content-between align-items-center">
        <b-card-title class="m-0">
          Takas Araç
        </b-card-title>
        <b-button
          v-b-toggle.sidebar-invoice-add-new-car
          variant="primary"
        >
          <FeatherIcon icon="PlusIcon" />
          Takas Araç Ekle
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-form-group
          label="Plaka"
          label-for="license_plate"
        >
          <b-input-group>
            <b-form-input
              id="license_plate"
              v-model="license_plate"
              placeholder="Plaka"
              @keydown.enter="searchSwapcar"
            />
            <b-input-group-append>
              <b-button
                variant="primary"
                @click="searchSwapcar"
              >
                <feather-icon
                  icon="SearchIcon"
                />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-table
          :items="swapcars"
          :fields="fields"
        >
          <template #cell(brand)="data">
            <div>{{ data.item.id }}</div>
            <div>{{ data.item.brand }}</div>
            <div class="text-primary font-small-2">
              {{ data.item.model }} {{ data.item.hardware }}
            </div>
          </template>
          <template #cell(control)="data">
            <b-button
              variant="danger"
              size="sm"
              @click="setSwapcar(data.item.id)"
            >
              Aracı Seç
            </b-button>
          </template>
        </b-table>
      </b-col>
      <b-col
        v-if="saleOrderForm.id_com_swapcar > 0"
        cols="12"
      >
        <swap-car-card
          v-if="saleOrderForm.swapcarData"
          :item-data="saleOrderForm.swapcarData"
        />
        <b-button
          class="mt-1"
          variant="danger"
          size="sm"
          @click="removeSwapcar"
        >
          <FeatherIcon icon="XIcon" />
          Takas Aracı Kaldır
        </b-button>
      </b-col>
    </b-row>
    <add-swap-car
      v-if="saleOrderForm.id_com_customer"
      :customer-id="saleOrderForm.id_com_customer"
      :refresh-data="searchSwapcar"
    />
  </div>
</template>

<script>
import {
  BRow, BCol,
  BCardTitle,
  BTable, BButton, BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend, VBToggle,
} from 'bootstrap-vue'
import { localize } from 'vee-validate'
import { required } from '@validations'
import AddSwapCar from '@/views/Sales/Sale_orderforms/components/AddSwapCar.vue'
import SwapCarCard from '@/views/Sales/Sale_orderforms/components/SwapCarCard.vue'

export default {
  name: 'SwapCar',
  components: {
    BRow,
    BCol,
    BCardTitle,
    BTable,
    BButton,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    AddSwapCar,
    SwapCarCard,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    updateSaleData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      license_plate: '',
      required,
      locale: 'tr',
      fields: [
        {
          key: 'brand',
          label: 'Araç',
        },
        {
          key: 'license_plate',
          label: 'Plaka',
        },
        {
          key: 'model_year',
          label: 'Model Yılı',
        },
        {
          key: 'control',
          label: '',
          tdClass: 'text-right',
        },
      ],
    }
  },
  computed: {
    swapcar() {
      return this.$store.getters['swapcars/getSwapcar']
    },
    swapcars() {
      return this.$store.getters['swapcars/getSwapcars']
    },
    saleOrderForm() {
      return this.$store.getters['saleOrderforms/getSaleOrderForm']
    },
  },
  created() {
    localize(this.locale)
  },
  methods: {
    setSwapcar(id) {
      if (id) {
        this.saleOrderForm.id_com_swapcar = id
        this.$store.dispatch('saleOrderforms/saveData', {
          id: this.saleOrderForm.id,
          id_com_swapcar: id,
        })
      }
      this.updateSaleData()
    },
    searchSwapcar() {
      this.$store.dispatch('swapcars/carSearch', {
        license_plate: this.license_plate,
        id_com_customer: this.saleOrderForm.id_com_customer,
      })
    },
    removeSwapcar() {
      this.$store.dispatch('saleOrderforms/saveData', {
        id: this.saleOrderForm.id,
        id_com_swapcar: '',
      })
      this.updateSaleData()
    },
  },
}
</script>
