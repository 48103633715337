<template>
  <b-form-group
    label="Bandrol Tutarı"
    label-for="banderole_price"
  >
    <validation-provider
      #default="{ errors }"
      name="Bandrol Tutarı"
      rules="required"
    >
      <b-input-group>
        <cleave
          id="banderole_price"
          v-model="saleOrderForm.banderole_price"
          class="form-control"
          :raw="true"
          :options="options.price"
          placeholder="Bandrol Tutarı"
        />
        <b-input-group-append is-text>
          ₺
        </b-input-group-append>
      </b-input-group>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import { required } from '@validations'

export default {
  name: 'BanderolePrice',
  components: {
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    Cleave,
    ValidationProvider,
  },
  data() {
    return {
      required,
      options: {
        price: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalMark: ',',
          delimiter: '.',
        },
      },
    }
  },
  computed: {
    saleOrderForm() {
      return this.$store.getters['saleOrderforms/getSaleOrderForm']
    },
  },
  created() {
    localize('tr')
  },
}
</script>
