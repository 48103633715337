<template>
  <b-form-group
    label="Doğum Tarihi"
    label-for="birthday"
  >
    <cleave
      id="birthday"
      v-model="customer.birthday"
      class="form-control"
      :raw="false"
      :options="options.dateMasked"
      placeholder="GG.AA.YYYY"
    />
  </b-form-group>
</template>

<script>
import {
  BFormGroup,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'

export default {
  name: 'Birthday',
  components: {
    BFormGroup,
    Cleave,
  },
  data() {
    return {
      options: {
        dateMasked: {
          date: true,
          delimiter: '.',
          datePattern: ['d', 'm', 'Y'],
        },
      },
    }
  },
  computed: {
    customer() {
      return this.$store.getters['customers/getCustomer']
    },
  },
}
</script>
