<template>
  <b-form-group
    label="İlçe"
    label-for="district"
  >
    <validation-provider
      #default="{ errors }"
      name="İlçe"
      rules="required"
    >
      <v-select
        id="district"
        v-model="customer.id_com_district"
        label="title"
        :reduce="district => district.id"
        :options="districts"
        placeholder="İlçe"
        :disabled="!customer.id_com_city"
        @input="getRegions"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>
<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'

export default {
  name: 'District',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      locale: 'tr',
    }
  },
  computed: {
    customer() {
      return this.$store.getters['customers/getCustomer']
    },
    districts() {
      return this.$store.getters['districts/getDistricts']
    },
  },
  created() {
    localize(this.locale)
  },
  methods: {
    getRegions() {
      if (this.customer.id_com_district) {
        this.customer.id_com_region = null
        this.$store.dispatch('regions/regionsList', {
          select: ['com_region.id AS id', 'com_region.title AS title'],
          where: {
            id_com_district: this.customer.id_com_district,
          },
        })
      }
    },
  },
}
</script>

<style scoped>

</style>
