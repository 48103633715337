<template>
  <b-form-group
    label="KDV Oranı"
    label-for="id_com_kdvrate"
  >
    <validation-provider
      #default="{ errors }"
      name="KDV Oranı"
      rules="required"
    >
      <v-select
        id="id_com_kdvrate"
        v-model="saleOrderForm.id_com_kdvrate"
        label="title"
        :reduce="kdvRate => kdvRate.id"
        :options="kdvRates"
        placeholder="Seçiniz"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  name: 'KdvRate',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      required,
      options: {
        price: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalMark: ',',
          delimiter: '.',
        },
      },
    }
  },
  computed: {
    saleOrderForm() {
      return this.$store.getters['saleOrderforms/getSaleOrderForm']
    },
    kdvRates() {
      return this.$store.getters['kdvRates/getKdvRates']
    },
  },
  created() {
    localize('tr')
  },
}
</script>
