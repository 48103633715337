<template>
  <b-form-group
    label="Renk"
    label-for="color"
  >
    <v-select
      id="color"
      v-model="swapcar.id_com_color"
      label="title"
      :reduce="color => color.id"
      :options="colors"
      placeholder="Renk"
    />
  </b-form-group>
</template>
<script>
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Brands',
  components: {
    BFormGroup,
    vSelect,
  },
  computed: {
    swapcar() {
      return this.$store.getters['swapcars/getSwapcar']
    },
    colors() {
      return this.$store.getters['colors/getColors']
    },
  },
  created() {
    this.getColors()
  },
  methods: {
    getColors() {
      this.$store.dispatch('colors/colorsList', {
        select: ['com_color.id AS id', 'com_color.title AS title'],
      })
    },
  },
}
</script>

<style scoped>
</style>
