<template>
  <div>
    <b-row>
      <b-col>
        <b-card-title>Araç Seçimi</b-card-title>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="Araç Şase No"
          label-for="chasis"
        >
          <b-input-group>
            <b-form-input
              id="chasis"
              v-model="chasis"
              placeholder="Araç Şase No"
              @keydown.enter="searchStock"
            />
            <b-input-group-append>
              <b-button
                variant="primary"
                :disabled="chasis.length < 4"
                @click="searchStock"
              >
                <feather-icon
                  icon="SearchIcon"
                />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="stocks.length">
      <b-col cols="12">
        <b-table
          :items="stocks"
          :fields="fields"
        >
          <template #cell(brand)="data">
            <div>{{ data.item.brand }} {{ data.item.model }}</div>
            <span class="text-primary font-small-2">{{ data.item.fuel }} {{ data.item.gear }} - {{ data.item.color }}</span>
          </template>

          <template #cell(control)="data">
            <b-button
              variant="danger"
              size="sm"
              :disabled="!choosingCar ? false : true"
              @click="setStock(data.item.id)"
            >
              <template v-if="!choosingCar">
                {{ chooseBtnTitle }}
              </template>
              <template v-if="choosingCar === 1">
                <b-spinner
                  small
                />
                {{ chooseBtnTitle }}
              </template>
              <template v-if="choosingCar === 2">
                <feather-icon icon="CheckCircleIcon" />
                {{ chooseBtnTitle }}
              </template>

            </b-button>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-form-group
          label="Şase No"
          label-for="chasis"
        >
          <validation-provider
            #default="{ errors }"
            name="Araç Seçimi"
            rules="required"
          >
            <b-form-input
              id="chasis"
              v-model="saleOrderForm.chasis"
              readonly
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BCardTitle, BFormGroup, BFormInput, BTable, BButton, BInputGroup, BInputGroupAppend, BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, localize } from 'vee-validate'
import { required } from '@validations'

export default {
  name: 'Stock',
  components: {
    ValidationProvider,
    BRow,
    BCol,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BTable,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BSpinner,
  },
  props: {
    updateSaleData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      required,
      chasis: '',
      chooseBtnTitle: 'Aracı Seç',
      choosingCar: 0,
      fields: [
        {
          key: 'brand',
          label: 'ARAÇ BİLGİSİ',
        },
        {
          key: 'myear',
          label: 'MODEL YILI',
        },
        {
          key: 'chasis',
          label: 'ŞASE',
        },
        {
          key: 'control',
          label: '',
          tdClass: 'text-right',
        },
      ],
    }
  },
  computed: {
    stocks() {
      return this.$store.getters['stock/getStocks']
    },
    saleOrderForm() {
      return this.$store.getters['saleOrderforms/getSaleOrderForm']
    },
  },
  created() {
    localize('tr')
  },
  methods: {
    setStock(id) {
      this.saleOrderForm.id_com_stock = id
      this.choosingCar = 1 // seciliyor
      this.chooseBtnTitle = 'Araç Seçiliyor..'
      this.$store.dispatch('saleOrderforms/saveData', this.saleOrderForm)
        .then(response => {
          if (response.status) {
            this.updateSaleData()
            this.choosingCar = 2 // secildi
            this.chooseBtnTitle = 'Seçildi.'
          }
        })
    },
    searchStock() {
      if (this.chasis.length > 3) {
        this.$store.dispatch('stock/stockSearch', this.chasis)
        this.chooseBtnTitle = 'Aracı Seç'
        this.choosingCar = 0
      }
    },
  },
}
</script>
