<template>
  <b-form-group
    label="Açıklama"
    label-for="pcontent"
  >
    <validation-provider
      #default="{ errors }"
      name="Açıklama"
      rules="required"
    >
      <b-form-input
        id="pcontent"
        v-model="saleOrderForm.payments.pcontent[rowIndex]"
        placeholder="Açıklama"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  name: 'PaymentContent',
  components: {
    BFormGroup,
    BFormInput,
    ValidationProvider,
  },
  props: ['rowIndex'],
  data() {
    return {
      required,
    }
  },
  computed: {
    saleOrderForm() {
      return this.$store.getters['saleOrderforms/getSaleOrderForm']
    },
  },
  created() {
    localize('tr')
  },
}
</script>
