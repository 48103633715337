<template>
  <div>
    <b-row>
      <b-col>
        <b-card-title>Ödeme</b-card-title>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <total-payment />
      </b-col>
    </b-row>
    <hr>
    <b-row
      v-for="(item, index) in saleOrderForm.payments.id_com_payment"
      :id="index"
      :key="index"
    >
      <b-col
        cols="12"
        md="4"
      >
        <payment-type :row-index="index" />
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <payment-price :row-index="index" />
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <payment-date :row-index="index" />
      </b-col>
      <b-col>
        <template v-if="contractedTips.includes(item)">
          <contracted-banks :row-index="index" />
        </template>
        <payment-content
          v-else
          :row-index="index"
        />
      </b-col>
      <b-col cols="auto">
        <b-button
          variant="danger"
          class="mt-0 mt-md-2"
          @click="removeItem(index)"
        >
          <feather-icon
            icon="TrashIcon"
            class="mr-25"
          />
          <span>Sil</span>
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-button
          variant="info"
          class="mb-1"
          size="sm"
          @click="addPartRow"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-25"
          />
          <span>Ödeme Ekle</span>
        </b-button>
      </b-col>
    </b-row>
    <hr>
    <b-row class="pt-2">
      <b-col>
        <licence-plate1 />
      </b-col>
      <b-col v-if="saleOrderForm.licence_plate1 === '1'">
        <licence-plate-group />
      </b-col>
      <b-col>
        <bank-pledge />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BCardTitle, BButton,
} from 'bootstrap-vue'
import { localize } from 'vee-validate'
import { required } from '@validations'
import TotalPayment from '@/views/Sales/Sale_orderforms/forms/elements/TotalPayment.vue'
import PaymentType from '@/views/Sales/Sale_orderforms/forms/elements/PaymentType.vue'
import PaymentPrice from '@/views/Sales/Sale_orderforms/forms/elements/PaymentPrice.vue'
import PaymentDate from '@/views/Sales/Sale_orderforms/forms/elements/PaymentDate.vue'
import PaymentContent from '@/views/Sales/Sale_orderforms/forms/elements/PaymentContent.vue'
import LicencePlate1 from '@/views/Sales/Sale_orderforms/forms/elements/LicencePlate1.vue'
import LicencePlateGroup from '@/views/Sales/Sale_orderforms/forms/elements/LicencePlateGroup.vue'
import BankPledge from '@/views/Sales/Sale_orderforms/forms/elements/BankPledge.vue'
import ContractedBanks from '@/views/Sales/Sale_orderforms/forms/elements/ContractedBanks.vue'

export default {
  name: 'Payment',
  components: {
    ContractedBanks,
    BRow,
    BCol,
    BCardTitle,
    BButton,
    TotalPayment,
    PaymentType,
    PaymentPrice,
    PaymentDate,
    PaymentContent,
    LicencePlate1,
    LicencePlateGroup,
    BankPledge,
  },
  data() {
    return {
      required,
      locale: 'tr',
      contractedTips: ['12', '13', '14'],
    }
  },
  computed: {
    saleOrderForm() {
      return this.$store.getters['saleOrderforms/getSaleOrderForm']
    },
    payments() {
      return this.$store.getters['payments/getPayments']
    },
  },
  created() {
    localize(this.locale)
    this.getPayments()
  },
  methods: {
    getPayments() {
      this.$store.dispatch('payments/paymentsList', {
        select: [
          'com_payment.id AS id',
          'com_payment.title AS title',
          'com_payment.subtitle AS subtitle',
        ],
      })
    },
    addPartRow() {
      this.saleOrderForm.payments.id_com_payment.push(null)
      this.saleOrderForm.payments.pprice.push(null)
      this.saleOrderForm.payments.pdate.push(null)
      this.saleOrderForm.payments.pcontent.push(null)
    },
    removeItem(index) {
      this.saleOrderForm.payments.id_com_payment.splice(index, 1)
      this.saleOrderForm.payments.pprice.splice(index, 1)
      this.saleOrderForm.payments.pdate.splice(index, 1)
      this.saleOrderForm.payments.pcontent.splice(index, 1)
    },
  },
}
</script>
