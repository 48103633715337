<template>
  <div>
    <b-alert
      v-if="dataLoading"
      show
      variant="info"
    >
      <div class="alert-body pt-3">
        <loading />
      </div>
    </b-alert>
    <template v-else>
      <form-wizard
        color="#c50d27"
        :title="null"
        :subtitle="null"
        layout="horizontal"
        finish-button-text="Kaydet"
        next-button-text="İleri"
        back-button-text="Geri"
        class="wizard-vertical mb-3"
      >
        <tab-content
          title="Müşteri Bilgileri"
          :before-change="validateStep1"
        >
          <validation-observer ref="simpleRules">
            <customer :alert-method="showAlert" />
          </validation-observer>
        </tab-content>
        <tab-content
          title="Araç Seçimi"
          :before-change="validateStep2"
        >
          <validation-observer ref="simpleRules2">
            <stock :update-sale-data="getSaleOrderForm" />
          </validation-observer>
        </tab-content>
        <tab-content
          title="Satış Bilgileri"
          :before-change="validateStep3"
        >
          <validation-observer ref="simpleRules3">
            <sale-info />
          </validation-observer>
        </tab-content>
        <tab-content
          title="Takas Araç"
          :before-change="validateStep4"
        >
          <swap-car :update-sale-data="getSaleOrderForm" />
        </tab-content>
        <tab-content
          title="Ödeme"
          :before-change="validateStep5"
        >
          <validation-observer ref="simpleRules5">
            <payment />
          </validation-observer>
        </tab-content>
      </form-wizard>
    </template>
  </div>
</template>
<script>
import { ValidationObserver, localize } from 'vee-validate'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { required } from '@validations'
// eslint-disable-next-line import/extensions
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { BAlert } from 'bootstrap-vue'
import customer from '@/views/Sales/Sale_orderforms/forms/Customer.vue'
import stock from '@/views/Sales/Sale_orderforms/forms/Stock.vue'
import saleInfo from '@/views/Sales/Sale_orderforms/forms/Sale_info.vue'
import swapCar from '@/views/Sales/Sale_orderforms/forms/Swap_car.vue'
import payment from '@/views/Sales/Sale_orderforms/forms/Payment.vue'
import Loading from '@/layouts/components/Loading.vue'

export default {
  name: 'SaleFormAdd',
  components: {
    BAlert,
    customer,
    stock,
    saleInfo,
    swapCar,
    payment,
    Loading,
    FormWizard,
    TabContent,
    ValidationObserver,
  },
  data() {
    return {
      required,
      locale: 'tr',
      submitStatus: false,
      dataLoading: true,
    }
  },
  computed: {
    saleOrderForm() {
      return this.$store.getters['saleOrderforms/getSaleOrderForm']
    },
    customer() {
      return this.$store.getters['customers/getCustomer']
    },
  },
  created() {
    this.$store.commit('saleOrderforms/SALE_ORDER_FORM_RESET', { status: true })
    localize(this.locale)
    this.getSaleOrderForm()
  },
  methods: {
    saleSaveData(finalStep = false) {
      this.$store.dispatch('saleOrderforms/saveData', this.saleOrderForm)
        .then(res => {
          if (res.status === true) {
            this.showAlert({
              variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: res.message,
            })
            if (finalStep) {
              this.$router.push(`/sale_orderforms/view/${res.id}`)
            }
          } else if (res.status === false) {
            this.showAlert({
              variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: res.message,
            })
          }
        })
    },
    getSaleOrderForm() {
      this.$store.dispatch('saleOrderforms/saleOrderFormsView', this.$route.params.order_number)
        .then(response => {
          if (response.id) {
            this.dataLoading = false
          }
          if (response.id_com_customer) {
            this.getCustomer(response.id_com_customer)
            this.getSwapCars(response.id_com_customer)
          }
          if (response.id_com_stock) {
            this.getStock(response.id_com_stock)
          }
        })
    },
    getCustomer(id) {
      this.$store.dispatch('customers/customerView', id)
    },
    getSwapCars(id) {
      this.$store.dispatch('swapcars/carSearch', {
        license_plate: '',
        id_com_customer: id,
      })
    },
    getStock(id) {
      this.$store.dispatch('stock/stock', id)
    },
    /* Customers */
    validateStep1() {
      return new Promise((resolve, reject) => {
        this.$refs.simpleRules.validate().then(success => {
          if (success) {
            this.$store.dispatch('customers/customerSave', this.customer)
            resolve(true)
            this.saleSaveData()
          } else {
            reject(new Error('Lütfen tüm zorunlu alanları doldurun.'))
          }
        })
      })
    },
    /* Stock Select */
    validateStep2() {
      return new Promise((resolve, reject) => {
        this.$refs.simpleRules2.validate().then(success => {
          if (success) {
            resolve(true)
            this.saleSaveData()
          } else {
            reject(new Error('Lütfen tüm zorunlu alanları doldurun.'))
          }
        })
      })
    },
    /* Sale Info */
    validateStep3() {
      return new Promise((resolve, reject) => {
        this.$refs.simpleRules3.validate().then(success => {
          if (success) {
            this.saleSaveData()
            resolve(true)
          } else {
            reject(new Error('Lütfen tüm zorunlu alanları doldurun.'))
          }
        })
      })
    },
    /* Swap Car */
    validateStep4() {
      return new Promise(resolve => {
        resolve(true)
        this.saleSaveData()
      })
    },
    /* Payments */
    validateStep5() {
      return new Promise((resolve, reject) => {
        this.$refs.simpleRules5.validate().then(success => {
          if (success) {
            resolve(true)
            this.saleSaveData(true)
          } else {
            reject(new Error('Lütfen tüm zorunlu alanları doldurun.'))
          }
        })
      })
    },
    showAlert(data) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: data.title,
          icon: 'BellIcon',
          text: data.text,
          variant: data.variant,
        },
      })
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
</style>
