<template>
  <b-form-group
    label="Banka Rehin"
    label-for="bank_pledge"
  >
    <validation-provider
      #default="{ errors }"
      name="Banka Rehin"
      rules="required"
    >
      <v-select
        id="bank_pledge"
        v-model="saleOrderForm.bank_pledge"
        label="title"
        :reduce="option => option.id"
        :options="options"
        placeholder="Seçiniz"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  name: 'BankPledge',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      required,
      options: [
        { id: '1', title: 'Var' },
        { id: '0', title: 'Yok' },
      ],
    }
  },
  computed: {
    payments() {
      return this.$store.getters['payments/getPayments']
    },
    saleOrderForm() {
      return this.$store.getters['saleOrderforms/getSaleOrderForm']
    },
  },
  created() {
    localize('tr')
    this.getPayments()
  },
  methods: {
    getPayments() {
      this.$store.dispatch('payments/paymentsList', {
        select: [
          'com_payment.id AS id',
          'com_payment.title AS title',
          'com_payment.subtitle AS subtitle',
        ],
      })
    },
  },
}
</script>
