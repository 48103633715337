<template>
  <b-form-group
    label="Anlaşmalı Banka"
    label-for="pcontent"
  >
    <validation-provider
      #default="{ errors }"
      name="Anlaşmalı Banka"
      rules="required"
    >
      <v-select
        id="pcontent"
        v-model="saleOrderForm.payments.pcontent[rowIndex]"
        label="title"
        :reduce="bank => bank.title"
        :options="banks"
        placeholder="Seçiniz"
      >
        <template v-slot:option="option">
          <div v-if="option.subtitle">
            {{ option.title }}
            <div>
              <small class="text-primary">{{ option.contracted }}</small>
            </div>
          </div>
          <div v-else>
            {{ option.title }}
          </div>
        </template>
        <template v-slot:selected-option="option">
          {{ option.title }}
          <small class="text-primary ml-1">{{ option.subtitle }}</small>
        </template>
      </v-select>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  name: 'ContractedBanks',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  props: ['rowIndex'],
  data() {
    return {
      required,
      id_com_bank: null,
    }
  },
  computed: {
    banks() {
      return this.$store.getters['banks/dataList']
    },
    saleOrderForm() {
      return this.$store.getters['saleOrderforms/getSaleOrderForm']
    },
  },
  created() {
    localize('tr')
    this.getBanks()
  },
  methods: {
    getBanks() {
      this.$store.dispatch('banks/getDataList', {
        select: [
          'com_banks.id AS id',
          'com_banks.title AS title',
          'com_banks.contracted AS contracted',
        ],
      })
    },
  },
}
</script>
