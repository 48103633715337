<template>
  <div>
    <b-row>
      <b-col>
        <b-card-title>Kişi Bilgileri</b-card-title>
      </b-col>
    </b-row>
    <customer-type />
    <b-row>
      <b-col
        v-if="customer.id_com_customer_type === '2'"
        sm="12"
        md="6"
      >
        <company-name />
      </b-col>
      <b-col
        v-if="customer.id_com_customer_type === '2'"
        sm="12"
        md="6"
      >
        <name-surname />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        v-if="customer.id_com_customer_type === '1'"
        cols="12"
        md="6"
      >
        <name-surname />
      </b-col>
      <b-col
        v-if="customer.id_com_customer_type === '1'"
        cols="12"
        md="6"
      >
        <birthday />
      </b-col>
      <b-col>
        <phone />
      </b-col>
      <b-col>
        <email />
      </b-col>
      <b-col>
        <job :is-required="true" />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        v-if="customer.id_com_customer_type === '1'"
        cols="6"
      >
        <tckn />
      </b-col>
      <b-col
        v-if="customer.id_com_customer_type === '2'"
        cols="6"
      >
        <tax-number />
      </b-col>
      <b-col
        cols="6"
      >
        <tax-admin />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <city />
      </b-col>
      <b-col>
        <district />
      </b-col>
      <b-col>
        <region />
      </b-col>
      <b-col>
        <neighborhood />
      </b-col>
      <b-col
        cols="12"
      >
        <Address />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BCardTitle,
} from 'bootstrap-vue'
import { localize } from 'vee-validate'
import { required } from '@validations'
import customerType from '@/views/Customers/forms/CustomerType.vue'
import Tckn from '@/views/Customers/forms/Tckn.vue'
import TaxNumber from '@/views/Customers/forms/TaxNumber.vue'
import TaxAdmin from '@/views/Customers/forms/TaxAdmin.vue'
import City from '@/views/Customers/forms/City.vue'
import District from '@/views/Customers/forms/District.vue'
import Region from '@/views/Customers/forms/Region.vue'
import Neighborhood from '@/views/Customers/forms/Neighborhood.vue'
import Address from '@/views/Customers/forms/Address.vue'
import nameSurname from '@/views/Customers/forms/NameSurname.vue'
import Email from '@/views/Customers/forms/Email.vue'
import Phone from '@/views/Customers/forms/Phone.vue'
import companyName from '@/views/Customers/forms/CompanyName.vue'
import Birthday from '@/views/Customers/forms/Birthday.vue'
import Job from '@/views/Customers/forms/Job.vue'

export default {
  name: 'Add',
  components: {
    Job,
    BRow,
    BCol,
    BCardTitle,
    Tckn,
    TaxNumber,
    TaxAdmin,
    City,
    District,
    Region,
    Neighborhood,
    Address,
    customerType,
    nameSurname,
    Phone,
    Email,
    companyName,
    Birthday,
  },
  props: {
    alertMethod: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      required,
      locale: 'tr',
    }
  },
  computed: {
    customer() {
      return this.$store.getters['customers/getCustomer']
    },
    saveStatus() {
      return this.$store.getters['customers/getCustomerSaveStatus']
    },
  },
  watch: {
    customer(val) {
      if (val.id) {
        this.loadedData()
      }
    },
    saveStatus(val) {
      const toastData = {}
      if (val.status) {
        if (val.status === true) {
          toastData.variant = 'success'
          toastData.title = 'İşlem Başarılı!'
          toastData.text = val.message
        } else {
          toastData.variant = 'danger'
          toastData.title = 'İşlem Hatası!'
          toastData.text = val.message
        }
        this.alertMethod(toastData)
      }
    },
  },
  created() {
    localize(this.locale)
  },
  methods: {
    loadedData() {
      if (this.customer.id) {
        if (this.customer.id_com_city) {
          this.$store.dispatch('districts/districtsList', {
            select: ['com_district.id AS id', 'com_district.title AS title'],
            where: {
              id_com_city: this.customer.id_com_city,
            },
          })
        }
        if (this.customer.id_com_district) {
          this.$store.dispatch('regions/regionsList', {
            select: ['com_region.id AS id', 'com_region.title AS title'],
            where: {
              id_com_district: this.customer.id_com_district,
            },
          })
        }
        if (this.customer.id_com_region) {
          this.$store.dispatch('neighborhoods/neighborhoodsList', {
            select: ['com_neighborhood.id AS id', 'com_neighborhood.title AS title'],
            where: {
              id_com_region: this.customer.id_com_region,
            },
          })
        }
      }
    },
  },
}
</script>
