<template>
  <b-form-group
    label="Marka"
    label-for="brand"
  >
    <v-select
      id="brand"
      v-model="swapcar.id_com_swap_brand"
      label="title"
      :reduce="brand => brand.id"
      :options="brands"
      placeholder="Marka"
      @input="getModels"
    />
  </b-form-group>
</template>
<script>
import { localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'

export default {
  name: 'Brands',
  components: {
    BFormGroup,
    vSelect,

  },
  data() {
    return {
      locale: 'tr',
    }
  },
  computed: {
    swapcar() {
      return this.$store.getters['swapcars/getSwapcar']
    },
    brands() {
      return this.$store.getters['swapbrands/getSwapbrands']
    },
  },
  created() {
    localize(this.locale)
    this.getBrands()
  },
  methods: {
    getBrands() {
      this.$store.dispatch('swapbrands/swapbrandsList', {
        select: ['com_swap_brand.id AS id', 'com_swap_brand.name AS title'],
      })
    },
    getModels() {
      if (this.swapcar.id_com_swap_brand) {
        this.swapcar.id_com_swap_model = null
        this.$store.dispatch('swapmodels/swapmodelsList', {
          select: ['com_swap_model.id AS id', 'com_swap_model.title AS title'],
          where: {
            id_com_swap_brand: this.swapcar.id_com_swap_brand,
          },
        })
      }
    },
  },
}
</script>

<style scoped>
</style>
