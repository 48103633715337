<template>
  <b-form-group
    label="Semt"
    label-for="region"
  >
    <validation-provider
      #default="{ errors }"
      name="Semt"
      rules="required"
    >
      <v-select
        id="region"
        v-model="customer.id_com_region"
        label="title"
        :reduce="region => region.id"
        :options="regions"
        placeholder="Semt"
        :disabled="!customer.id_com_district"
        @input="getNeighborhoods"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>
<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'

export default {
  name: 'Region',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      locale: 'tr',
    }
  },
  computed: {
    customer() {
      return this.$store.getters['customers/getCustomer']
    },
    regions() {
      return this.$store.getters['regions/getRegions']
    },
  },
  created() {
    localize(this.locale)
  },
  methods: {
    getNeighborhoods() {
      if (this.customer.id_com_region) {
        this.customer.id_com_neighborhood = null
        this.$store.dispatch('neighborhoods/neighborhoodsList', {
          select: ['com_neighborhood.id AS id', 'com_neighborhood.title AS title'],
          where: {
            id_com_region: this.customer.id_com_region,
          },
        })
      }
    },
  },
}
</script>

<style scoped>

</style>
