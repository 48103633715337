<template>
  <b-form-group
    label="Donanım Paketi"
    label-for="hardware"
  >
    <b-form-input
      id="hardware"
      v-model="swapcar.hardware"
      placeholder="Donanım Paketi"
    />
  </b-form-group>
</template>
<script>
import { localize } from 'vee-validate'
import {
  BFormGroup, BFormInput,
} from 'bootstrap-vue'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'

export default {
  name: 'Brands',
  components: {
    BFormGroup,
    BFormInput,
  },
  data() {
    return {
      locale: 'tr',
    }
  },
  computed: {
    swapcar() {
      return this.$store.getters['swapcars/getSwapcar']
    },
  },
  created() {
    localize(this.locale)
  },
}
</script>
<style scoped>
</style>
