<template>
  <b-row>
    <b-col class="text-center">
      <div class="bg-gradient-primary text-light rounded pt-1 pb-1">
        <div class="font-weight-light">
          Toplam Tahsilat Tutarı
        </div>
        <div class="font-weight-bolder font-large-1">
          {{ totalPayment.value | toCurrency }} ₺
        </div>
      </div>
    </b-col>
    <b-col class="text-center">
      <div
        class="text-light rounded pt-1 pb-1"
        :class="balance.value === 0? 'bg-gradient-success' : 'bg-gradient-danger'"
      >
        <div class="font-weight-light">
          Kalan Bakiye
        </div>
        <div class="font-weight-bolder font-large-1">
          {{ balance.value | toCurrency }} ₺
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'

export default {
  name: 'TotalPayment',
  components: {
    BRow,
    BCol,
  },
  data() {
    return {
      totalpayment: null,
    }
  },
  computed: {
    totalPayment() {
      return {
        value: this.saleOrderForm.payments.pprice.reduce((a, b) => Number(a) + Number(b), 0),
      }
    },
    balance() {
      return {
        value: this.saleOrderForm.sale_price - this.totalPayment.value,
      }
    },
    saleOrderForm() {
      return this.$store.getters['saleOrderforms/getSaleOrderForm']
    },
  },
}
</script>
