<template>
  <b-form-group
    label="Ödeme Tarihi"
    :label-for="'pdate_' + rowIndex"
  >
    <validation-provider
      #default="{ errors }"
      name="Ödeme Tarihi"
      rules="required"
    >
      <b-form-datepicker
        :id="'pdate_' + rowIndex"
        v-model="saleOrderForm.payments.pdate[rowIndex]"
        v-bind="{labelNoDateSelected: 'Tarih',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
        locale="tr"
        start-weekday="1"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup, BFormDatepicker,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  name: 'PaymentDate',
  components: {
    BFormGroup,
    BFormDatepicker,
    ValidationProvider,
  },
  props: ['rowIndex'],
  data() {
    return {
      required,
    }
  },
  computed: {
    saleOrderForm() {
      return this.$store.getters['saleOrderforms/getSaleOrderForm']
    },
  },
  created() {
    localize('tr')
  },
}
</script>
