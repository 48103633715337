<template>
  <b-form-group
    label="Mahalle"
    label-for="neighborhood"
  >
    <validation-provider
      #default="{ errors }"
      name="Mahalle"
      rules="required"
    >
      <v-select
        id="neighborhood"
        v-model="customer.id_com_neighborhood"
        label="title"
        :reduce="neighborhood => neighborhood.id"
        :options="neighborhoods"
        placeholder="Mahalle"
        :disabled="!customer.id_com_region"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>
<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'

export default {
  name: 'Neighborhood',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      locale: 'tr',
    }
  },
  computed: {
    customer() {
      return this.$store.getters['customers/getCustomer']
    },
    neighborhoods() {
      return this.$store.getters['neighborhoods/getNeighborhoods']
    },
  },
  created() {
    localize(this.locale)
  },
}
</script>

<style scoped>

</style>
