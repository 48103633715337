<template>
  <b-form-group
    label="Model Yılı"
    label-for="myear"
  >
    <cleave
      id="myear"
      v-model="swapcar.model_year"
      class="form-control"
      :raw="false"
      :options="options.myear"
      placeholder="Model Yılı"
    />
  </b-form-group>
</template>

<script>
import { localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'

export default {
  name: 'ModelYear',
  components: {
    BFormGroup,
    Cleave,
  },
  data() {
    return {
      options: {
        myear: {
          blocks: [4],
          numericOnly: true,
        },
      },
    }
  },
  computed: {
    swapcar() {
      return this.$store.getters['swapcars/getSwapcar']
    },
  },
  created() {
    localize('tr')
  },
}
</script>

<style scoped></style>
