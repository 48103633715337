<template>
  <b-form-group
    label="Plaka"
    label-for="license_plate"
  >
    <validation-provider
      #default="{ errors }"
      name="Plaka"
      rules="required"
    >
      <b-form-input
        id="license_plate"
        v-model="swapcar.license_plate"
        placeholder="Plaka"
        maxlength="8"
        @keydown="intializePlate"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { localize, ValidationProvider } from 'vee-validate'
import { BFormGroup, BFormInput } from 'bootstrap-vue'
import { required } from '@validations'

export default {
  name: 'Brands',
  components: {
    ValidationProvider,
    BFormGroup,
    BFormInput,
  },
  data() {
    return {
      locale: 'tr',
      required,
    }
  },
  computed: {
    swapcar() {
      return this.$store.getters['swapcars/getSwapcar']
    },
  },
  created() {
    localize(this.locale)
  },
  methods: {
    intializePlate(event) {
      let plate = event.target.value.toUpperCase() // Giriş büyük harfe çevrilir
      plate = plate.replace(/[^A-Z0-9]/g, '') // Sadece harf ve rakamları bırak, boşlukları çıkar

      // ilk iki rakam sayı
      if (plate.length > 0 && !/^[0-9]{2}/.test(plate.slice(0, 2))) {
        plate = plate.slice(0, 2) // İlk iki karakter rakam değilse sadece iki rakamı bırak
      }
      this.swapcar.license_plate = plate
    },
  },
}
</script>
