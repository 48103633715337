<template>
  <b-form-group
    label="Model"
    label-for="model"
  >
    <v-select
      id="model"
      v-model="swapcar.id_com_swap_model"
      label="title"
      :reduce="model => model.id"
      :options="models"
      placeholder="Model"
    />

  </b-form-group>
</template>
<script>
import { localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'

export default {
  name: 'Models',
  components: {
    BFormGroup,
    vSelect,
  },
  data() {
    return {
      locale: 'tr',
    }
  },
  computed: {
    swapcar() {
      return this.$store.getters['swapcars/getSwapcar']
    },
    models() {
      return this.$store.getters['swapmodels/getSwapmodels']
    },
  },
  created() {
    localize(this.locale)
  },
}
</script>

<style scoped>
</style>
