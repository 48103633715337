<template>
  <div>
    <b-list-group v-if="itemData.id">
      <b-list-group-item>
        <div class="text-primary font-weight-bold">
          Araç Modeli
        </div>
        {{ itemData.swap_brand }} {{ itemData.swap_model }} {{ itemData.hardware }}
      </b-list-group-item>
      <b-list-group-item>
        <div class="text-primary font-weight-bold">
          Yakıt / Vites
        </div>
        {{ itemData.fuel }} {{ itemData.gear }}
      </b-list-group-item>
      <b-list-group-item>
        <div class="text-primary font-weight-bold">
          Renk
        </div>
        {{ itemData.color }}
      </b-list-group-item>
      <b-list-group-item>
        <div class="text-primary font-weight-bold">
          Model Yılı
        </div>
        {{ itemData.model_year }}
      </b-list-group-item>
      <b-list-group-item>
        <div class="text-primary font-weight-bold">
          Km
        </div>
        {{ itemData.km | toNumber }}
      </b-list-group-item>
      <b-list-group-item v-if="itemData.swap_price">
        <div class="text-primary font-weight-bold">
          Takas Bedeli
        </div>
        {{ itemData.swap_price | toCurrency }}₺
      </b-list-group-item>
      <b-list-group-item v-if="itemData.license_owner">
        <div class="text-primary font-weight-bold">
          Ruhsat Sahibi
        </div>
        {{ itemData.license_owner }}
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import { BListGroup, BListGroupItem } from 'bootstrap-vue'

export default {
  name: 'SwapCarCard',
  components: {
    BListGroup,
    BListGroupItem,
  },
  props: {
    itemData: {
      type: Object,
      require: false,
    },
  },
}
</script>
